import { useState, useEffect } from "react";
import createApiInstance from "../data/services/api";
import { useRequestManager } from "services/RequestManagerContext";
import axios from "axios";

/**
 * 
 * @typedef {'POST' | 'GET' | 'PUT' | 'DELETE'} ApiMethod
 * 
 */

// Custom hook for using Fetch API dynamically
/**
 * 
 * @param {Object} param 
 * @param {Object} param.data 
 * @param {string} param.path 
 * @param {ApiMethod} param.method 
 * @param {boolean} param.isFormData 
 * @param {boolean} param.isAppApi 
 * @param {Object | null} param.param 
 * @param {boolean | null} param.initialLoading
 */
export default function useFetch({ method = "POST", path, data = {}, isFormData = false, isAppApi = false, param, initialLoading }) {
  const api = createApiInstance({ isAppApi: isAppApi, isFormData: isFormData });
  const { registerRequest } = useRequestManager();
  const [startFetching, setStartFetching] = useState(false);
  const [loading, setLoading] = useState(initialLoading ?? true);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (startFetching) fetchData();
  }, [startFetching, registerRequest]);

  const paramConverter = () => {
    return Object.entries(param).reduce((prev, [key, value]) => { return `${prev}&${key.toString()}=${value.toString()}` }, '')
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await api.request({
        method,
        url: `/${path}${param ? `?${paramConverter()}` : ''}`,
        data,
        // cancelToken: registerRequest(),
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;
          setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const progress =
            50 + (progressEvent.loaded / progressEvent.total) * 50;
          setProgress(progress);
        },
      });

      setResponse(result.data?.data ?? result.data);
    } catch (error) {
      if (!axios.isCancel(error)) setError(error);
    } finally {
      // setProgress(0)
      setStartFetching(false);
      setLoading(false);
    }
  };

  return {
    response,
    setStartFetching,
    setProgress,
    progress,
    loading,
    responseError: error,
  };
}
