import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Navbar, Form, InputGroup } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../routes";
import Logo from "assets/img/logo.png";
import TextDivider from "../composes/TextDivider";
import { useAuth } from "data/context/auth";
import { MenuPage } from "constants/menu_pages";
import Navbars from "./Navbar";

const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary", autorezed = "", hidden = true, } = props;
    const location = useLocation();
    const { pathname } = location;

    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = pathname.includes(link) ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    if (!hidden) return null;
    return (
        <Nav.Link
            {...linkProps}
            target={target}
            className={`list-group-item list-group-item-action ${navItemClassName}`}
            style={{
                width: "revert",
                marginRight: "15px",
                color: "#fff",
                fontSize: 12,
                paddingBlock: "5px",
                whiteSpace: "normal",
                wordBreak: "break-word",
                borderRadius: "6px",
                background: navItemClassName === "" ? "transparent" : ""
            }}
        >
            <div className={classNames}>
                {icon ? (
                    <span className="sidebar-icon">
                        <FontAwesomeIcon icon={icon} />{" "}
                    </span>
                ) : null}

                {image ? (
                    <Image
                        src={image}
                        className="sidebar-icon svg-icon"
                        style={{
                            maxWidth: "76%",
                            padding: "9px"
                        }}
                    />
                ) : null}

                <span className="sidebar-text">{title}</span>

                {autorezed ? (
                    <span className="sidebar-autorezed">{autorezed}</span>
                ) : null}

                {badgeText ? (
                    <Badge
                        pill
                        bg={badgeBg}
                        text={badgeColor}
                        className="badge-md notification-count ms-2"
                    >
                        {badgeText}
                    </Badge>
                ) : null}
            </div>
        </Nav.Link>
    );
};


const SideBar = () => {
    const { user, autorizes } = useAuth();
    const [show, setShow] = useState(false);
    const showClass = show ? "show" : "";

    const [routeMap, setRouteMap] = useState([])
    const [filterText, setFilterText] = useState("");

    const onCollapse = () => setShow(!show);

    useEffect(() => {
        setRouteMap([])
        const result = MenuPage(autorizes, user?.email ?? null)
            .filter((d) => d.sidebar && d?.hidden && d.hidden)
            .filter((item) => {
                return Object
                    .values(item)
                    .some((value) => value && typeof value === "string" && value.trim().toLowerCase().includes(filterText.toLowerCase()));
            })
            .reduce(function (r, a) {
                r[a.category] = r[a.category] || [];
                r[a.category].push(a);
                return r;
            }, Object.create(null));

        for (let value of Object.keys(result)) {
            if (result[value]?.hidden === undefined) setRouteMap(current => [...current, { sectionTitle: value || <>&nbsp;</> }])
            setRouteMap(current => [...current, ...result[value]])
        }
    }, [filterText, autorizes])

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.shiftKey && event.key === 'Alt') {
                const searchInput = document.getElementById('search-menu');
                if (searchInput) {
                    searchInput.value = searchInput.value
                    searchInput.focus();
                }
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <>
            <Navbar
                expand={false}
                collapseOnSelect
                variant="dark"
                className="px-4 d-md-none"
                style={{ backgroundColor: "#0e101d" }}
            >
                <Navbar.Brand
                    className="me-lg-5"
                    as={Link}
                    to={Routes.DashboardOverview.path}
                >
                    <Image src={Logo} className="navbar-brand-light" />
                </Navbar.Brand>
                <Navbar.Toggle
                    as={Button}
                    aria-controls="main-navbar"
                    onClick={onCollapse}
                >
                    <span className="navbar-toggler-icon" />
                </Navbar.Toggle>
            </Navbar>

            <nav
                className={`collapse ${showClass} d-md-block`}
                style={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    top: 0,
                    zIndex: 100,
                    width: "100%",
                    maxWidth: "260px",
                    height: "100%",
                    maxHeight: "100%",
                    overflowY: "hidden",
                    backgroundColor: "rgb(19 22 40)",
                    transition: "max-width .3s",
                }}
            >
                <div className="d-flex flex-column px-2">
                    <NavItem title="" image={Logo} />
                    <Navbars />

                    <InputGroup size="sm" className="mt-3 mb-1">
                        <InputGroup.Text
                            className="bg-transparent"
                        >
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control
                            type="search"
                            placeholder="Search... (shift + alt)"
                            className="bg-transparent"
                            autoFocus={true}
                            id="search-menu"
                            title="click `shift + alt` to search menu"
                            style={{ zIndex: 999999 }}
                            onChange={(e) => setFilterText(e.target.value)}
                        />
                    </InputGroup>
                </div>

                <div
                    className="small-scrollbar ps-2"
                    style={{
                        height: "80%",
                        overflowY: "scroll",
                    }}
                >
                    <div className="list-group gap-1">
                        {(routeMap?.length === 0) && <small className="text-sm mx-auto text-muted">No results for <strong className="text-white">{filterText}</strong>.</small>}
                        {
                            (routeMap?.length > 0) && routeMap?.map((e, i) => {
                                return (
                                    <>
                                        {(e?.sectionTitle && e?.sectionTitle !== undefined) && <TextDivider key={i} text={e?.sectionTitle} hidden={!e.hidden} />}

                                        {e.title && <NavItem
                                            key={i + 1}
                                            title={e.title}
                                            link={e.path}
                                        />}
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </nav>
        </>
    );
};


export default SideBar