import { createContext, useState, useContext, useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate, useLocation, Route } from "react-router-dom";
import createApiInstance from "../services/api";
import { MenuPage } from "constants/menu_pages";
import { UnrestrictedRoute } from "routes";
import { LocalCookies } from "features/global/data/localCookies";
import { routeList, route, routeMap } from "refactor_routes";
import useFetch from "hooks/useFetch";

const AuthContext = createContext({
  user: null,
  authenticate: (newToken) => Promise(),
  isLoading: false,
  isAuthenticated: false,
  autorizes: [],
  isSuperAdmin: false,
  token: "",
  refresh: () => { }
});

export const AuthProvider = ({ children }) => {
  const history = useNavigate();
  const api = createApiInstance();

  const [user, setUser] = useState(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [autorizes, setAutorizes] = useState([]);


  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies?.get("token");
      const user = Cookies?.get("meta");
      const autorize = Cookies?.get("autorize");
      if (token && user) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
        setUser(JSON.parse(user));
        autorize && setAutorizes(JSON.parse(autorize));
      }
      setLoading(false);
    }
    loadUserFromCookies();
  }, []);

  const login = async (username, password) => {
    setLoading(true);

    const { data, status } = await api.post(`/auth/login`, { email: username, password: password, });
    const { accessToken: token, autorize, ...props } = data.data;

    if (status && token) {
      sessionStorage.setItem("user", JSON.stringify(props));
      Cookies.set("meta", JSON.stringify(props));
      Cookies.set("autorize", JSON.stringify(autorize));
      setAutorizes(autorize);
      setUser(data.data);
      setIsSuperAdmin(props?.email !== "superadmin@youapp.ai")

      Cookies.set("token", token, { expires: 60 });
      api.defaults.headers.Authorization = `Bearer ${token}`;

      LocalCookies.filterCountry.set(data.data?.filterCountry)
      LocalCookies.filterCountry.setDefault(data.data?.filterCountry)
      // console.log(`country ${LocalCookies.filterCountry.get()}`)
    }

    return data;
  };

  const logout = async () => {
    try {
      await api.post(`/auth/logout`, { token: Cookies.get("token"), });

      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      Cookies.remove("user");
      Cookies.remove("token");
      LocalCookies.filterCountry.delete();
      LocalCookies.filterCountry.deleteDefault();
      setUser(null);
      setAutorizes([]);
      history("/login");
    } catch (error) {
      console.log("failed logout");
      console.warn(error);
    }
  };


  const clearTokenOnUnload = () => {
    // Hapus token dari Cookies saat tab ditutup
    sessionStorage.removeItem("token");
  };

  const refresh = async () => {
    try {
      const { data, status } = await api.get(`/auth/authorize`);
      const { autorize, ...props } = data.data;

      if (status) {
        sessionStorage.setItem("user", JSON.stringify(props));
        Cookies.set("meta", JSON.stringify(props));
        Cookies.set("autorize", JSON.stringify(autorize));
        setAutorizes(autorize);
        setUser(data.data);
        setIsSuperAdmin(props?.email !== "superadmin@youapp.ai")
        LocalCookies.filterCountry.setDefault(data.data?.filterCountry)
      }
    } catch (error) {
      console.log('failed to refresh : ', error);
      console.warn('failed to refresh : ', error);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        login,
        logout,
        isLoading,
        autorizes,
        isSuperAdmin,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};


export const useAuth = () => useContext(AuthContext);

export const ProtectRoute = ({ children }) => {
  const history = useNavigate();
  const api = createApiInstance();
  const token = Cookies.get("token");
  const { isLoading, user, autorizes, refresh } = useAuth();
  const location = useLocation();

  const isAccessible = () => {
    const unrestrictedPage = [...Object.values(UnrestrictedRoute)].map((value) => value.path)
    if (unrestrictedPage.includes(location.pathname)) return true
    const pageList = MenuPage(autorizes, user?.email ?? null).reduce((prev, curr, index, arr) => {
      if (curr?.auth?.value)
        if (autorizes?.includes(curr?.auth?.value ?? '')) {
          prev.push(curr)
        }
      return prev;
    }, [])
    if (pageList.map((value) => value.path).includes(location.pathname)) return true
    if (!MenuPage(autorizes, user?.email ?? null).map((value) => value.path).includes(location.pathname)) return true

    return false
  }

  useEffect(() => {
    if (!isLoading) {
      if (token && user) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
        if (location.pathname === '/' && autorizes?.includes("dashboard:view")) {
          history(routeMap().DashboardOverview.path)
        }
        if (!isAccessible()) {
          history(routeMap().AccessDenied.path)
        }
        if (location.pathname === '/login') {
          history(routeMap().DashboardOverview.path)
        }
        refresh()
      } else if (location.pathname !== '/login' && typeof window !== "undefined") {

        history("/login");
      }
    }
  }, [isLoading, token, history, location.pathname])

  return children;
};
